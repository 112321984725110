import React from 'react'
import Navbar from './NavbarSecond'
import ContactBanner from './ContactBanner'
import VisitingCardFront from './Assets/images/encode-visiting-card-front.png'
import VisitingCardBack from './Assets/images/encode-visiting-card-back.png'


function Contact() {
  return (
    <>
        <Navbar/>
        <ContactBanner/>
        <div className='container-fluid my-3'>
            <div className='row'>
                <div className='col-md-6'>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfGO6E3mJQ0xm1Yn8mAXjVGOm3hTNzQn93TH4B-cyrUXix-PA/viewform?embedded=true" width="100%" height="700" frameborder="0" marginheight="0" marginwidth="0" title='...'>Loading…</iframe>
                </div>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid my-3' src={VisitingCardFront} alt='Visiting Card Front'/><br/>
                    <img className='img-fluid' src={VisitingCardBack} alt='Visiting Card Back'/>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contact