import React from 'react'
import AboutUsBanner from './Assets/images/about-us-banner.jpg'
import {
    MDBCarousel,
    MDBCarouselItem,
  } from 'mdb-react-ui-kit';

function AboutBanner() {
  return (
    <div>
        <MDBCarousel>
            <MDBCarouselItem
                className='w-100 d-block'
                itemId={1}
                src={AboutUsBanner}
                alt='AboutUsBanner'
            />
        </MDBCarousel>
    </div>
  )
}

export default AboutBanner