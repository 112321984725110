import React from 'react'
import HeroVideo from './Assets/videos/home-main-banner.mp4'
import { MDBBtn } from 'mdb-react-ui-kit';

function HomeVideo() {
  return (
    <>
      <div className='main-slider'>
        <div className='overlay'></div>
        <video src={HeroVideo} muted autoPlay loop/>
        <div className='hero-content'>
            <h1 className='display-3 text-center text-white'>Welcome to Encode Studio</h1>
            <span className='display-6 text-center text-white'>Technology | Innovation | Aspiration</span>
            <div className="d-grid gap-2 col-4 mx-auto">
              <a href='about'><MDBBtn color='info' className='my-2'>Lets Go</MDBBtn></a>
            </div>
        </div>
      </div>
    </>
  )
}

export default HomeVideo