import React from 'react'
import NavbarSecond from './NavbarSecond'
import AboutBanner from './AboutBanner'
import Shivam from './Assets/images/shivam.png'

function About() {
  return (
    
    <>
      <NavbarSecond/>
      <AboutBanner/>
      <div className='container-fluid my-3'>
        <div className='row'>
          <div className='col-md-6'>
            <p align='justify'>
              Welcome to <strong>Encode Studio</strong>, your trusted partner in digital innovation. With over 10 years of experience in website development, web application development, graphic design, UI/UX designing, DevOps, and more, we are committed to transforming your ideas into captivating digital experiences.
            </p>
            <p align='justify'>
              At <strong>Encode Studio</strong>, we understand that the digital landscape is constantly evolving, and staying ahead of the curve is essential for businesses to thrive. That's why we have assembled a team of highly skilled professionals who are passionate about creating innovative solutions that drive results.
            </p>
            <p align='justify'>
              Our expertise lies in website development, where we craft visually stunning and fully functional websites tailored to meet the unique needs of your business. Whether you require a simple informational website or a complex e-commerce platform, our team will work closely with you to understand your goals and deliver a solution that exceeds your expectations.
            </p>
            <p align='justify'>
              In addition to website development, we are proficient in web application development, empowering businesses with powerful tools to streamline their operations and enhance productivity. Our experienced developers leverage cutting-edge technologies to create custom web applications that automate processes, facilitate collaboration, and improve overall efficiency.
            </p>
            <p align='justify'>
              We also take pride in our graphic design services, where we bring your brand to life through captivating visuals. From logo design and branding materials to marketing collateral and digital advertisements, our talented designers have a keen eye for aesthetics and a deep understanding of the power of visual communication.
            </p>
            <p align='justify'>
              User experience (UX) and user interface (UI) play a vital role in the success of any digital product. Our skilled designers are experts in crafting intuitive and visually appealing interfaces that enhance user engagement and drive conversions. By conducting thorough research and employing best practices, we create seamless user experiences that leave a lasting impression on your audience.
            </p>
            <p align='justify'>
              At <strong>Encode Studio</strong>, we believe in the power of collaboration. Our team works closely with you, keeping communication channels open throughout the entire project lifecycle. We value your insights and ideas, ensuring that your vision is brought to life while incorporating our expertise and industry best practices.
            </p>
            <p align='justify'>
              Furthermore, we understand the importance of technology infrastructure in today's digital world. Our DevOps specialists ensure that your applications and websites are deployed, managed, and scaled efficiently. By leveraging automation and cloud technologies, we optimize performance, security, and reliability, allowing you to focus on your core business objectives.
            </p>
            <p align='justify'>
              Partnering with <strong>Encode Studio</strong> means gaining a dedicated team of professionals who are committed to your success. We strive for excellence in every project we undertake, consistently delivering high-quality solutions that exceed expectations. Your satisfaction is our top priority.
            </p>
            <p align='justify'>
              Contact us today to embark on a digital journey that will transform your business and elevate your brand. Let <strong>Encode Studio</strong> be your guide to success in the ever-evolving digital landscape.
            </p>
          </div>
          <div className='col-md-6 my-3 text-center'>
            <img className='img-fluid' src={Shivam} alt='Shivam'/>
          </div>
        </div>
      </div>
    </>
  )
}

export default About