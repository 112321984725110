import React from 'react'
import HomeVideo from './HomeVideo'
import Navbar from './Navbar'

function Home() {
  return (
    <>
      <Navbar/>
        <HomeVideo/>
    </>
  )
}

export default Home